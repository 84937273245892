<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="d-flex flex-row align-center py-2">
          <h4 class="mx-2">Dolares Planeados</h4>
        </div>
        <div class="d-flex mb-0 pb-0">
          <v-text-field
            label="USD/ha"
            class="managrx-input mr-2"
            outlined
            v-model.number="usdHa"
            dense
            :filled="!paymentPlanEditMode"
            :readonly="!paymentPlanEditMode"
            type="number"
          ></v-text-field>
          <v-text-field
            label="Cantidad de Hectáreas"
            type="number"
            v-model.number="physicalHectares"
            outlined
            dense
            :filled="!paymentPlanEditMode"
            :readonly="!paymentPlanEditMode"
            class="managrx-input mr-2"
          ></v-text-field>

          <v-text-field
            label="Cantidad de cuotas"
            class="managrx-input mr-2"
            outlined
            dense
            :value="numCuotes"
            type="number"
            filled
            readonly
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    <v-row>
      <v-col cols="12">
        <v-form ref="form" class="d-flex flex-column ml-4 mt-4 pt-4">
          <div class="d-flex flex-row align-center pt-2">
            <h4 class="mx-2">Pagos</h4>
          </div>

          <div class="d-flex mt-2 mb-2">
            <v-text-field
              label="Monto"
              class="managrx-input mr-2"
              v-model.number="newRow.value"
              outlined
              :rules="required"
              dense
              type="number"
            ></v-text-field>
            <v-text-field
              label="Fecha"
              class="managrx-input mr-2"
              v-model="newRow.date"
              outlined
              :rules="required"
              dense
              type="date"
            ></v-text-field>
            <v-btn
              @click="saveNewRow"
              color="primary"
              :depressed="false"
              class="mr-2"
            >
              <v-icon> mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" v-for="row in rentFixedRows" :key="row.id">
        <div class="d-flex" v-if="row.id == editRow.id">
          <v-text-field
            label="Monto Pagado"
            class="managrx-input mr-2"
            v-model="editRow.value"
            outlined
            dense
            type="number"
          ></v-text-field>
          <v-text-field
            label="Fecha"
            class="managrx-input mr-2"
            v-model="editRow.date"
            outlined
            dense
            type="date"
          ></v-text-field>
          <v-btn
            @click="saveEditRow"
            color="primary"
            :depressed="false"
            class="ml-2 mr-2"
          >
            <v-icon> mdi-floppy</v-icon>
          </v-btn>
          <v-btn color="secondary" :depressed="false" @click="exitEditRow">
            Atrás
          </v-btn>
        </div>
        <div class="d-flex" v-else>
          <v-text-field
            label="Monto Pagado"
            class="managrx-input mr-2"
            :value="row.value"
            outlined
            readonly
            dense
            type="number"
            filled
          ></v-text-field>
          <v-text-field
            label="Fecha"
            class="managrx-input mr-2"
            :value="ISODateToInputDate(row.date)"
            outlined
            readonly
            dense
            type="date"
            filled
          ></v-text-field>
          <v-btn
            color="red white--text"
            @click="confirmDeleteRow"
            :depressed="false"
            v-if="deleteRow.id == row.id"
          >
            Borrar
          </v-btn>
          <v-btn
            color="warning"
            :depressed="false"
            @click="editRowClick(row)"
            class="mr-2"
            v-else
          >
            <v-icon> mdi-pencil</v-icon>
          </v-btn>
          <v-btn
            color="red lighten-2 white--text"
            :depressed="false"
            @click="exitDeleteRow"
            v-if="deleteRow.id == row.id"
            class="ml-2"
          >
            Atrás
          </v-btn>
          <v-btn
            @click="deleteRowClick(row)"
            color="red white--text darken-2"
            :depressed="false"
            v-else
          >
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { roundToX } from "../../../../managrx/utils";
export default {
  name: "AbstractFixUSD",
  props: {
    parsedDefaultDate: String,
    rentPaymentPlan: Array,
    rentFixedRows: Array,
    physicalHectares: Number,
  },
  computed: {
    usdHa() {
      return roundToX(
        this.rentFixedRows?.map((el) => el?.value).reduce((a, b) => a + b, 0) /
          this.physicalHectares,
        2
      );
    },
    required() {
      return [(v) => !!v || "Campo obligatorio"];
    },
    numCuotes() {
      return this.rentFixedRows?.length;
    },
  },
  methods: {
    saveEditRentPaymentPlanClick() {
      this.paymentPlanEditMode = false;
      let payload = {
        id: this.rentUsdPaymentPlan?.id,
        row: {
          hectares: this.rentUsdPaymentPlan?.hectares,
          priceHa: this.rentUsdPaymentPlan?.priceHa,
        },
      };
      this.$emit("saveEditPlannedUsd", payload);
    },
    editRentPaymentPlanClick() {
      this.paymentPlanEditMode = true;
    },
    exitEditRentPaymentPlanClick() {
      this.paymentPlanEditMode = false;
      let planned = this.rentPaymentPlan?.[0];
      this.rentUsdPaymentPlan = {
        ...planned,
      };
    },
    exitEditRow() {
      this.editRow = {
        id: null,
      };
    },

    saveEditRow() {
      let payload = {
        id: this.editRow?.id,
        row: {
          value: this.editRow?.value,
          date: new Date(this.editRow?.date).toISOString(),
        },
      };
      this.$emit("saveEditFixedUsd", payload);
      this.exitEditRow();
    },
    editRowClick(row) {
      this.editRow = { ...row, date: this.ISODateToInputDate(row.date) };
    },
    exitDeleteRow() {
      this.deleteRow = {
        id: null,
      };
    },
    deleteRowClick(row) {
      this.deleteRow = row;
    },
    confirmDeleteRow() {
      this.$emit("deleteFixedUsd", this.deleteRow?.id);
    },
    saveNewRow() {
      if (this.$refs.form.validate()) {
        let payload = {
          ...this.newRow,
          date: new Date(this.newRow?.date).toISOString(),
        };
        this.$emit("saveNewFixedUsd", payload);
        this.newRow = {
          value: null,
          date: new Date().toISOString().split("T")[0],
        };
        this.$refs.form.resetValidation();
      }
    },
    ISODateToInputDate(date) {
      return date.split("T")[0];
    },
  },
  mounted() {
    let planned = this.rentPaymentPlan[0];
    this.rentUsdPaymentPlan = {
      ...planned,
    };
  },
  data() {
    return {
      editRow: { id: null },
      deleteRow: { id: null },
      newRow: {
        value: null,
        date: this.parsedDefaultDate,
      },
      rentUsdPaymentPlan: {
        hectares: null,
        priceHa: null,
      },
      editMode: false,
      paymentPlanEditMode: false,
    };
  },
};
</script>

<style></style>
