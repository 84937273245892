<template>
  <v-container>
    <v-row class="my-6" no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn color="primary white--text px-2" @click="downloadExcel">
          <v-icon> mdi-file-download-outline </v-icon>
          <span class="px-2">Descargar</span>
        </v-btn>
      </v-col></v-row
    >
    <v-row no-gutters>
      <v-col sm="5" offset-sm="2" md="6" offset-md="0">
        <v-btn
          color="red white--text px-2"
          dark
          @click.stop="deleteEngagementDialog = true"
        >
          <v-icon> mdi-delete </v-icon> <span class="px-2">Borrar</span>
        </v-btn>

        <v-dialog v-model="deleteEngagementDialog" max-width="505">
          <v-card>
            <v-card-title class="text-h5">
              ¿Esta seguro que desea eliminar este Negocio?
            </v-card-title>

            <v-card-text>
              Al apretar Eliminar se borraran todos los datos ingresados para el
              este Negocio y no podran ser recuperados.
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                color="green darken-1"
                text
                @click="deleteEngagementDialog = false"
              >
                Cancelar
              </v-btn>

              <v-btn
                color="green darken-1"
                text
                @click="deleteSingleEngagement"
              >
                Eliminar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { URL } from "../../managrx/services.js";

export default {
  name: "Actions",
  methods: {
    async downloadExcel() {
      this.$store.dispatch("setPageLoadingStatus", true);
      let engagementId = this.$route.params.id;
      try {
        const response = await axios.get(
          this.URL + `/module/get/excel/engagement/${engagementId}`,
          {
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `engagement_${engagementId}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        let notif = {
          success: false,
          data: {
            text: "Error descargando el archivo",
            type: "alert",
          },
        };
        this.$store.dispatch("setNewNotification", notif);
      }

      this.$store.dispatch("setPageLoadingStatus", false);
    },
    deleteSingleEngagement() {
      this.deleteEngagementDialog = false;
      this.$emit("deleteSingleEngagement");
    },
  },
  data() {
    return {
      URL: URL,
      deleteEngagementDialog: false,
    };
  },
};
</script>

<style></style>
